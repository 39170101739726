import React, { useRef } from 'react'
import { Canvas, useFrame } from 'react-three-fiber'

const CanvasStyles = {
  height: '100vh',
  background: '#000',
}

const Ring = (props) => {
  const mesh = useRef()

  useFrame(
    () => (mesh.current.rotation.x = mesh.current.rotation.y += props.augment)
  )

  return (
    <mesh {...props} ref={mesh} scale={[0.2, 0.2, 0.2]}>
      <torusBufferGeometry attach="geometry" args={[16, 0.08, 16, 100]} />
      <meshStandardMaterial attach="material" color={'#fff' || '#84dcc6'} />
    </mesh>
  )
}

export const background = () => (
  <Canvas style={CanvasStyles}>
    <ambientLight intensity={0.3} />
    <pointLight position={[10, 10, 10]} />
    {Array(8).fill().map((_, index) => (
      <>
        <Ring augment={0.0001 * (index * index)} />
        <Ring augment={0.0001 * -(index * index)} />
      </>
    ))}
  </Canvas>
)

export default background
